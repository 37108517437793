import {SafeHtml} from '@angular/platform-browser';

import {Media} from './media';
import {Stream} from './stream';
import {ThumbnailResolutions} from './thumbnail_resolutions';
import {NoteRating} from './note_rating';
import {User} from './user';
import {Comment} from './comment';
import {Location} from './location';


export type NoteState = 'PUBLIC'
  | 'HIDDEN' | 'TAKEN_DOWN';


export class Note {
  public id: string;
  public parentPostId: string[] = [];  // If part of a thread, the parent ID this is a reply to
  public childPosts: string[] = []; // responses to this note
  public channelId: string;
  public debaters: number[]; // array of users IDs, who can create debate notes
  public stream?: Stream;
  public userId: number;
  public user?: User;
  public state: NoteState = 'HIDDEN';
  public updated: number;
  public created: number;
  public published: number;
  public featured: number;
  public price: number;
  public name: string;
  public indexedName: string;
  public urlName: string;
  public tags: string[];
  public enComments: number;
  public shortURLPath: string;
  public pinned: number;
  public leadContent: SafeHtml;
  public leadWrdCnt = 0; // word count of lead content
  public firstPremWords: SafeHtml;
  public firstLeadWords: SafeHtml;
  public premiumContent: SafeHtml;
  public premMedia: Media[];
  public premWrdCnt = 0; // word count of premium content
  public pubTimestamp: number;
  public status: number;
  public thumbnailImage: string;
  public thumbnails: ThumbnailResolutions;
  public trashed: number;
  public commentsCount: string;
  public comments?: Comment[];
  public location: Location;
  public ratingAvg: number;
  public viewCount: number;

  rating: number;
  ratings: NoteRating[];
  ratingsCount: number;

  leadMediaType: string;
  premiumMediaType: string;

  private _streamInfo: Stream;
  private _leadMedia: Media[];
  private _premiumMedia: Media[];

  constructor(noteData: any) {
    this.id = noteData.id || '';
    this.channelId = noteData.channelId || '';
    this.userId = noteData.userId || undefined;
    this.parentPostId = noteData.parentPostId || [];
    this.childPosts = noteData.childPosts || [];
    this.name = noteData.name || '';
    this.debaters = noteData.debaters || [];
    this.state = noteData.state || 'HIDDEN';
    this.updated = noteData.updated || 0;
    this.created = noteData.created || 0;
    this.published = noteData.published || 0;
    this.featured = noteData.featured || 0;

    this.urlName = this.urlifyName(this.name);
    this.tags = noteData.tags;
    this.enComments = noteData.enComments;
    this.shortURLPath = noteData.shortURLPath;
    this.pinned = noteData.pinned;
    this.indexedName = noteData.indexedName;
    this.price = noteData.price;
    this.tags = noteData.tags;
    this.leadWrdCnt = noteData.leadWrdCnt;
    this.premWrdCnt = noteData.premWrdCnt;
    this.pubTimestamp = noteData.pubTimestamp;
    this.firstLeadWords = noteData.firstLeadWords;
    this.firstPremWords = noteData.firstPremWords;
    this.status = noteData.status;
    this.thumbnailImage = noteData.thumbnailImage;
    this.thumbnails = noteData.thumbnails;
    this.commentsCount = noteData.commentsCount;

    this.ratings = noteData.ratings || [];
    this.ratingsCount = this.ratings.length;
    this.rating = this.ratings.reduce((result, value, index) => result + value.rating, 0) / this.ratingsCount;
    this.ratingAvg = noteData.ratingAvg;

    this.leadMediaType = this.premiumMediaType = 'text';
    this.leadMedia = noteData.leadMedia;
    this.premiumMedia = noteData.premiumMedia;
    this.premMedia = this._premiumMedia;
    this.location = noteData.location ? this.createLocationObject(noteData.location) : new Location({});
  }

// just for this single file disable, because many things to override
// https://stackoverflow.com/a/66001071/374421
/* eslint-disable @typescript-eslint/member-ordering */

  get leadMedia() {
    return this._leadMedia;
  }
  set leadMedia(media: Media[]) {
    this._leadMedia = media;
    this.leadMediaType = (media && media.length > 0) ? this.getMediaType(media[0]) : 'text';
  }

  get streamInfo(): Stream {
    if (this._streamInfo) {
      return this._streamInfo;
    } else {
      return {
        name: ''
      } as Stream;
    }
  }
  set streamInfo(channelInfo) {
    this._streamInfo = channelInfo;
  }

  get premiumMedia() {
    return this._premiumMedia;
  }
  set premiumMedia(media: Media[]) {
      // for testing
      if (this.id === '5765983402721280_0_1504450531929') {
        if (media && media[0] && media[0].transcoded && media[0].transcoded.playlistUrl) {
          media[0].transcoded.playlistUrl = 'https://s3.amazonaws.com/perplay-media-transcoded/test/audio/test_playlist.m3u8';
        }
        this._premiumMedia = media;
        this.premiumMediaType = 'audio';
        return;
      }
      // end for testing

      this._premiumMedia = media;
      this.premiumMediaType = (media && media.length > 0) ? this.getMediaType(media[0]) : 'text';
  }

  public addNewRating(rating: NoteRating) {
    this.ratings.push(rating);
    this.ratingsCount = this.ratings.length;
    this.rating = this.ratings.reduce((result, value, index) => result + value.rating, 0) / this.ratingsCount;
  }

  public needsPayment(): boolean {
    return this.status === 402;
  }

  public getLeadWords() {
    return this.firstLeadWords || this.firstPremWords;
  }

  public getUrlifyName() {
    return this.urlifyName(this.name);
  }

  private createLocationObject(locationData) {
    if (locationData.type && locationData.type === 'Point') {
      return new Location({latitude: locationData.coordinates[0], longitude: locationData.coordinates[1]});
    }

    return new Location({address: locationData.address || '', latitude: null, longitude: null});
  }

  private urlifyName(name: string) {
    let str = name.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to   = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length ; i < l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  }

  private getMediaType(media: any) {
    const primaryMedia = media;
    if (media && media.mime && typeof media.mime === 'string') {
      if (media.mime.indexOf('audio') !== -1) {
        return 'audio';
      } else if (media.mime.indexOf('video') !== -1) {
        return 'video';
      }
    }
    return 'text';
  }
}
