import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LyTheme2} from '@alyle/ui';
import {ImgCropperConfig, ImgCropperEvent, LyImageCropper} from '@alyle/ui/image-cropper';

import {styles} from './image-editor-styles';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Output() uploaded: EventEmitter<any> = new EventEmitter();
  @Input() customClass: string;
  @Input() currentImg: string;
  @Input() imgAlt: string;
  @Input() imgWidth: number;
  @Input() imgHeight: number;
  @Input() imgOutputWidth: number;
  @Input() imgOutputHeight: number;
  @Input() onlyModal: boolean;
  @Input() lazyLoading: {enable: boolean; mainThumb?: string | null; thumbs?: string[] | null | boolean} = {
    enable: false
  };
  @ViewChild('imageEditorModal', {static: false}) imageEditorModal: ElementRef;
  @ViewChild(LyImageCropper, {static: false}) imgCropper: LyImageCropper;
  classes = this.theme.addStyleSheet(styles);
  croppedImg: any;
  scale: number;
  myConfig: ImgCropperConfig;
  cropper: any;
  imageLoaded = false;
  originalImg = null;

  constructor(
    private theme: LyTheme2,
    private modalService: NgbModal
  ) {
  }

  public ngOnInit(): void {
    this.setMyConfig();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).some(key => changes[key].currentValue !== undefined)) {
      this.setMyConfig();
    }
  }

  /** on event */
  onCrop(e: ImgCropperEvent) {
    this.croppedImg = e;
  }

  onloaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }

  onerror(e: ImgCropperEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  onImageLoaded(e: Event) {
    this.imageLoaded = true;
    this.originalImg = null;

    // if (e['width'] <= this.imgWidth && e['height'] <= this.imgHeight) {
    //   this.setOriginalImg(e);
    // }
  }

  getCropper(cropper: any) {
    this.cropper = cropper;
  }

  setOriginalImg(e: any) {
    this.originalImg = Object.assign({}, e, {
      dataURL: e.originalDataURL
    });
  }

  editImageModal() {
    if (this.cropper) {
      this.cropper.clean();
      if (this.cropper.scale < 0.1) {
        this.scale = 1;
      }
    }
    const modalOptions: NgbModalOptions = {};

    modalOptions.windowClass = 'image-editor-modal';
    modalOptions.backdrop = 'static';

    const modalRef = this.modalService.open(this.imageEditorModal, modalOptions);

    modalRef.result.then(resp => {
      if (resp === 'save') {
        this.uploaded.emit(this.originalImg ? this.originalImg : this.croppedImg);
        this.originalImg = null;
      } else {
        this.originalImg = null;
      }
    }).catch(e => {
      console.log('error: ', e);
      this.originalImg = null;
    });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
  }

  private setMyConfig(): void {
    this.myConfig = {
      width: this.imgWidth || 200,
      height: this.imgHeight || 200,
      output: {
        width: this.imgOutputWidth || 1200,
        height: this.imgOutputHeight || 1200,
      }
    };
  }
}
