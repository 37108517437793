// service to generate universal meta data

// just for this single file disable, because many things to override
/* eslint-disable @typescript-eslint/naming-convention */


import * as Debug from 'debug';
import { Injectable, Inject, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {DomSanitizer, Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

import {NoteService} from './note_service';
import {StreamService} from './stream_service';
import {UserService} from './user_service';

import { Note } from '../types/note';
import { User } from '../types/user';

import {environment} from '../../../environments/environment';

const debug = Debug('notd:shared:MetaService');

@Injectable()
export class MetaService {
    keywords: MetaDefinition;
    description: MetaDefinition;

    defaultMetaTags: any;

    title: string;
    notdLogo: string;
    notdLogoSquare: string;

    constructor(
        public meta: Meta,
        public pageTitle: Title,
        private route: ActivatedRoute,
        private noteService: NoteService,
        private streamService: StreamService,
        private userService: UserService,
        private domSanitizer: DomSanitizer,
        private router: Router,
        @Inject(DOCUMENT) private document: Document ) {

        this.defaultMetaTags = {
          title: 'Notd.io',
          description: 'Notd is a media subscription network for individuals and publishers.',
          keywords: 'Notd.io'
        };

        this.keywords = {
          name: 'keywords',
          content: 'Notd.io'
        };

        this.setDefaultTags();

        this.router.events.subscribe( evt => {
            if (evt.constructor.name === 'NavigationStart') {
              this.clearSpecialTags();
              this.setMetaPropertyTag('og:url', `${environment.urlBasePrefix}${evt['url']}`);
              this.setMetaPropertyTag('twitter:url', `${environment.urlBasePrefix}${evt['url']}`);
              this.setMetaPropertyTag('og:site_name', this.defaultMetaTags.title);
              this.setMetaPropertyTag('og:type', 'website');
              this.setMetaPropertyTag('og:image', this.notdLogo);
              this.setMetaPropertyTag('twitter:image', this.notdLogoSquare);

                if (
                  evt['url'] === '/' ||
                  evt['url'].startsWith('/info')
                ) {
                  this.setTitle(this.defaultMetaTags.title);
                  this.setDescription(this.defaultMetaTags.description);
                } else if (evt['url'].indexOf('notes/') >= 0 || evt['url'].indexOf('/n/') === 0) {
                  this.getIdFromUrl(evt['url'], 'notes').then((id: string) => {
                    this.setNoteTags(id);
                  });
                } else if (evt['url'].indexOf('thread/') >= 0) {
                  this.getIdFromUrl(evt['url'], 'thread').then((id: string) => {
                    this.setNoteTags(id);
                  });
                } else if (evt['url'].indexOf('streams/') >= 0 && evt['url'].indexOf('user/') < 0) {
                  this.getIdFromUrl(evt['url'], 'streams').then((id: string) => {
                    this.setStreamTags(id);
                  });
                } else if (evt['url'].indexOf('streams/') >= 0 && evt['url'].indexOf('user/') >= 0) {
                  this.getIdFromUrl(evt['url'], 'streams-user').then((id: string) => {
                    this.setStreamTags(id);
                  });
                } else if (evt['url'].indexOf('/s/') === 0) {
                  this.getIdFromUrl(evt['url'], 'streams').then((id: string) => {
                    this.setStreamTags(id);
                  });

                } else if (evt['url'].indexOf('/user/') === 0) {
                  this.getIdFromUrl(evt['url'], 'user').then((userId: string) => {
                    this.setUserTags(userId);
                  });

                } else if (evt['url'].indexOf('/search') >= 0) {
                    this.setTitle(`Search - ${this.defaultMetaTags.title}`);
                    this.setMetaPropertyTag('og:type', 'website');
                } else if (evt['url'].indexOf('/promotion') >= 0) {
                    this.setTitle('$100 Promotion');
                    this.setDescription(
                      'Like to Write? Get $100 for Trying Notd. '
                    + 'We just launched Notd, where writers and publishers '
                    + 'can offer micro-subscriptions to their news, opinions, '
                    + 'commentary – whatever they write. And to show what a '
                    + 'great way it is to publish (and read), we are offering '
                    + 'writers $100 to try it out. ');
                  } else if (evt['url'].indexOf('/why-publish') >= 0) {
                    this.setTitle('Notd.io: The Publishing Network You’ve Been Waiting For.');
                    this.setDescription(
                      'The Publishing Network You’ve Been Waiting For. '
                    + 'You’re a writer. Maybe you already have a following, '
                    + 'or maybe you’re just starting out. You need a '
                    + 'publishing platform, but most of them exist primarily '
                    + 'to make money from your work. ');
                    const imageUrl = `${environment.urlBasePrefix}/assets/why-publish/reading-man.png`;
                    this.setMetaPropertyTag('og:image', imageUrl);
                    this.setMetaPropertyTag('twitter:image', imageUrl);
                  } else if (evt['url'].indexOf('/how-notd-works') === 0) {
                    this.setTitle('Notd.io: A Fully Customizable, Ad-Free News and Information Network ');
                    this.setDescription(
                      'Notd is a revolutionary publishing network: '
                    + 'a go-to source for unbiased news, podcasts, '
                    + 'newsletters, poetry, and more.');
                    const imageUrl = `${environment.urlBasePrefix}/assets/why-publish/reading-man.png`;
                    this.setMetaPropertyTag('og:image', imageUrl);
                    this.setMetaPropertyTag('twitter:image', imageUrl);
                  } else if (evt['url'].indexOf('/sitemap') === 0) {
                    this.setTitle('Notd.io: Sitemap');
                    this.setDescription(
                      'Notd sitemap');
                } else {
                    this.setDefaultTags();
                    this.setTitle(this.defaultMetaTags.title);
                    this.setKeywords(this.defaultMetaTags.keywords);
                    this.setDescription(this.defaultMetaTags.description);
                }
            }
        });
    }

    setDefaultTags() {
        const prevDescriptionTags = this.meta.getTags('name="description"');

        if (prevDescriptionTags.length <= 0) {
            this.notdLogo = `${environment.urlBasePrefix}/assets/favicon.png`;
            this.notdLogoSquare = `${environment.urlBasePrefix}/assets/favicon.png`;
            this.title = 'Notd.io';
            this.description = {
                name: 'description',
                content: this.defaultMetaTags.description,
            };
            this.keywords = {
                name: 'keywords',
                content: this.defaultMetaTags.keywords
            };
            this.setTitle(this.title);
            this.setKeywords(this.defaultMetaTags.keywords);
            this.setDescription(this.defaultMetaTags.description);
        }
    }

    setTitle(title: string) {
        this.title = title;
        this.pageTitle.setTitle(this.title);
        this.setMetaPropertyTag('og:title', this.title);
        this.setMetaPropertyTag('twitter:title', this.title);
    }

    setKeywords(keywords: string) {
        this.keywords.content = keywords;
        if (this.meta.getTag('name="keywords"')) {
            this.meta.updateTag(this.keywords);
        } else {
            this.meta.addTag(this.keywords);
        }
    }

    setDescription(description: string | null): void {
      if  (!description || description.length < 2) {
        return;
      }
      description = description.replace(/\n/g, '').replace(/<.*?>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      if (this.description && ('content' in this.description) && description) {
        this.description.content = description;

        this.meta.updateTag(this.description);

        if (this.meta.getTag('name="description"')) {
            this.meta.updateTag(this.description);
        } else {
            this.meta.addTag(this.description);
        }

        this.setMetaPropertyTag('og:description', description);
        this.setMetaPropertyTag('twitter:description', description);
      }
    }

    setMetaPropertyTag( tag: string, content: string) {
        const metaDef: MetaDefinition = { property: tag, content };
        if (this.meta.getTag('property="' + tag + '"')) {
            this.meta.updateTag(metaDef);
        } else {
            this.meta.addTag(metaDef);
        }
    }

    setNoteTags(noteId: string) {
      this.noteService.getNoteInfo(noteId).then((res: Note) => {
          const title = `${res.name} - ${this.defaultMetaTags.title}`;
          this.setTitle(title);
          this.setDescription(res['firstLeadWords'].toString() || res['firstPremWords'].toString() || '');

          // see https://developers.google.com/search/docs/data-types/article
          // https://developers.google.com/search/docs/data-types/paywalled-content
          const ldJson =  {
            '@context': 'https://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': NoteService.getFullURL(res.id, res.name),
            },
            headline: title,
            isAccessibleForFree: 'False',
            image: [
             ],
            publisher: {
              name: 'Notd.io',
              '@type': 'Organization',
              logo: {
                 '@type': 'ImageObject',
                 url: environment.urlBasePrefix + '/assets/logo-black.png'
            },
            hasPart: {
              '@type': 'WebPageElement',
              isAccessibleForFree: 'False',
              cssSelector : '.paywall'
              }
           },

            datePublished: (new Date(res.published)).toISOString(),
            dateModified:  (new Date(res.updated)).toISOString()
          };


          if (res.thumbnailImage) {
              this.setMetaPropertyTag('og:image', res.thumbnailImage);
              this.setMetaPropertyTag('twitter:image', res.thumbnailImage);
              ldJson.image.push(res.thumbnailImage);
          } else {
              if (res.hasOwnProperty('stream') && res.stream.hasOwnProperty('iconImageUrl') && res.stream.iconImageUrl) {
                  this.setMetaPropertyTag('og:image', res.stream.iconImageUrl);
                  this.setMetaPropertyTag('twitter:image', res.stream.iconImageUrl);
                  ldJson.image.push(res.stream.iconImageUrl);
              }

          }
          this.setLdJSON(ldJson);


      }, (err) => {
        console.log('Could not get note info!', err);
      });
    }

    setUserTags(userNameOrId: string) {

      this.userService.getUserProfile(userNameOrId).then( (user: User) => {
        if (!user) {
          return;
        }

        if (user.profileImageUrl) {
          this.setMetaPropertyTag('og:image', user.profileImageUrl);
          this.setMetaPropertyTag('twitter:image', user.profileImageUrl);
        }
        if (user.bio) {
          this.setDescription(user.bio);
        }
        if (user.displayName) {
          this.setTitle(`${user.displayName} - ${this.defaultMetaTags.title}`);
          this.setKeywords(`Notd.io writer author ${user.displayName}`);
        } else {
          this.setTitle(this.defaultMetaTags.title);
        }
      });

    }

    setStreamTags(streamId: string) {
      this.streamService.getStreamInfoObservable(streamId).subscribe(res => {
        if (res) {
          this.setTitle(`${res.name} - ${this.defaultMetaTags.title}`);
          this.setMetaPropertyTag('og:type', 'website');
          this.setDescription(res.description);

          if (res.iconImageUrl) {
            this.setMetaPropertyTag('og:image', res.iconImageUrl);
            this.setMetaPropertyTag('twitter:image', res.iconImageUrl);
          }
        }
      }, (err) => {
        console.log('Could not get stream info!', err);
      });
    }

    private clearSpecialTags() {
        this.meta.removeTag('property="og:type"');
        this.meta.removeTag('property="og:image"');
        this.meta.removeTag('property="twitter:image"');
    }

    private setLdJSON(ldJson: any) {
      const script = this.document.createElement('script');
      script.type = 'application/ld+json';
      const js = JSON.stringify(ldJson);
      script.textContent = js;

      this.document.head.appendChild(script);
    }

    private getIdFromUrl(url: string, type: string): Promise<number | string> {
      const urlSplit = url.split('/');
      if (url.indexOf('/s/') >= 0 ) {
        const customUrlString = urlSplit[2];
        return this.streamService.getStreamIdFromCustomUrl(customUrlString)
          .then(result => result.channel.id)
          .catch(err => console.error('Error while getting stream', err));
      } else if (url.indexOf('/n/') >= 0 ) {
        const customUrlString = urlSplit[2];

        return this.noteService.getNoteIdFromCustomUrl(customUrlString)
          .then(result => result.post.id)
          .catch(err => console.error('Error while getting note', err));
      } else if (url.indexOf('/notes/publish-thank-you') >= 0 ) {
        const customUrlString = urlSplit[3];

        return new Promise(resolve => {
          resolve(customUrlString);
        });
      } else {
        return new Promise(resolve => {
//          console.log(`MetaService  type: ${MetaService}`)
          if (type === 'notes') {
            resolve(urlSplit[2]);
          }
          if (type === 'streams') {
            resolve(urlSplit[2]);
          }
          if (type === 'streams-user') {
            resolve(urlSplit[4]);
          }
          if (type === 'user') {
            resolve(urlSplit[2]);
          }
          if (type === 'thread') {
            resolve(urlSplit[2].split('?')[0]);
          }
        });
      }
    }
}
